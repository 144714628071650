<template>
  <div>
    <!--Our-Services-->
    <section id="services" class="section-padding">
      <div class="container">
        <div class="section-header text-center">
          <h2>Admin <span>Area</span></h2>
        </div>
      </div>
      <div class="section-body">
        <b-container>
          <b-row>
            <b-col>
            </b-col>
            <b-col class="text-center">
              <!-- TODO update v-if logic! -->
              <form v-if="user === null" @submit.prevent="logIn">
                <div class="mb-3">
                  <label for="inputEmail1" class="form-label">Email address</label>
                  <input type="email" class="form-control" id="inputEmail1" aria-describedby="emailHelp" v-model="email" >
                </div>
                <div class="mb-3">
                  <label for="inputPassword1" class="form-label">Password</label>
                  <input type="password" class="form-control" id="inputPassword1" v-model="password" >
                </div>
                <button type="submit" class="btn btn-primary">Login</button>
              </form>
              <form v-else @submit.prevent="logOut">
                Logged In: {{user.email}}
                <button type="submit" class="btn btn-primary">Logout</button>
              </form>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
  </div>
           

</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Admin',
  data () {
    return {
      email: null,
      password: null
    }
  },
  computed:{
    ...mapState('user', ['user', 'token'])
  },
  mounted() {
    this.checkLoggedIn()
  },
  methods: {
      ...mapActions('user', ['signUserIn', 'signUserOut', 'checkLoggedIn']),
      logIn () {
        this.signUserIn({ email: this.email, password: this.password })
      },
      logOut () {
        this.signUserOut()
      }
  }
}
</script>
